.rainbow-button {
    background: linear-gradient(90deg, #FF5F6D, #FFC371); /* Custom gradient with red-orange tones */
    background-size: 200%; /* Smooth gradient animation */
    color: white;
    padding: 12px 24px;
    font-size: 16px;
    border: none;
    border-radius: 12px; /* Softer border radius */
    cursor: pointer;
    transition: background-position 0.5s, box-shadow 0.3s;
    font-weight: bold;
    outline: none;

    &:hover {
        background-position: 100%; /* Shift gradient on hover */
    }

    &:active {
        transform: scale(0.98); /* Slightly shrink on click */
    }

    &:focus {
        box-shadow: 0 0 8px rgba(255, 99, 71, 0.8); /* Add focus glow effect */
    }
}


/* Estilos para o Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent; /* Fundo completamente transparente */
  backdrop-filter: blur(10px) brightness(0.4); /* Desfoque + leve escurecimento */
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s; /* Animação de entrada */
  overflow: auto; /* Permite rolagem quando necessário */
  z-index: 100;
}


.modal-content {
    background: #2a2a2a; /* Fundo interno do modal */
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 1000px;
    animation: slideIn 0.3s;
}

.close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
    transition: color 0.2s;

    &:hover {
        color: #333;
    }
}

/* Estilo dos planos */
.plans {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 1rem;
}

.plan {
    background: #222;
    color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

    h3 {
        font-size: 20px;
        color: #f78d3f;
    }

    p {
        margin: 8px 0;
    }
}

@media (min-width: 600px) {
    .plans {
        flex-direction: row;
    }
}



.subscription-modal {
    text-align: center;
    color: #fff;
    background-color: transparent; /* Torne o fundo da página transparente */
    // background-color: #1f1f1f;

    h1 {
      font-size: 2rem;
      margin-bottom: 3rem;
      color: #fff;
    }
  
    .plans {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }
  
    .plan-card {
      background-color: #2a2a2a;
      border-radius: 10px;
      padding: 20px;
      width: 300px;
      position: relative;
      transition: transform 0.3s;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  
      &:hover {
        transform: scale(1.05);
      }
  
      &.popular {
        border: 2px solid #ff9800;
      }
  
      .badge {
        position: absolute;
        top: -10px;
        right: -10px;
        background: #ff9800;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 0.8rem;
        font-weight: bold;
      }
  
      h2 {
        font-size: 1.5rem;
        margin-bottom: 10px;
      }
  
      .price {
        font-size: 1.2rem;
        margin: 10px 0;
        color: #ff9800;
      }
  
      .features {
        list-style-type: none;
        padding: 0;
  
        li {
          padding: 5px 0;
          border-bottom: 1px solid #444;
        }
      }
  
      .subscribe-button {
        background: linear-gradient(90deg, #ff4b2b, #ff9800);
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 1rem;
        padding: 10px 15px;
        margin-top: 15px;
        cursor: pointer;
        transition: background 0.3s;
  
        &:hover {
          background: linear-gradient(90deg, #ff9800, #ff4b2b);
        }
      }
    }
  }
  