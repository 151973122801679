@import url('../styles/constants.scss');

.form-check {
    text-align: start;
    margin-left: 0.75rem;
    label {
        font-family: 'Poppins', sans-serif;
        color: var(--white);
    }
}

#client-data {
    // margin-bottom: 30px;

    h2 {
        text-align: start;
        color: var(--white);

        span {
            border-bottom: solid var(--bg-cmp-primary) 8px;
        }
    }
}

.financial-data {
    display: flex;
    flex-direction: column;
    gap: 16px;

    margin-bottom: 10px;
}

.client-data-subtitle, .financial-data-subtitle {
    margin-bottom: 15px;
}

.input-group .form-floating {
    margin-top: 0;
}

#actionButton {
    margin-top: 0.5rem;
}

.client-cards {
    margin-bottom: 20px;
}

