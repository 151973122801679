@import url('../../styles/constants.scss');

.button {
    height: 50px;
    border-radius: 8px;
    font-weight: 500;
    background: var(--bg-cmp-primary);
    color: var(--white);
    padding: 0 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    border: 0;

    transition: filter 0.2s;

    img {
        margin-right: 8px;
    }

    &.outlined {
        background: var(--white);
        border: 1px solid var(--bg-cmp-primary);
        color: var(--bg-cmp-primary);

        &.danger {
            background: transparent;
            border: 1px solid var(--bg-cmp-danger);
            color: var(--bg-cmp-danger);

            &:not(:disabled):hover {
                background: rgba(220, 53, 70, 0.2);
                filter: brightness(0.8);
            }
        }
    }

    &.primary {
        background: var(--bg-cmp-primary);
    }

    &.danger {
        background: var(--bg-cmp-danger);
    }

    &.info {
        background: (--bg-cmp-info);
    }

    &.secondary {
        background: var(--bg-cmp-secondary);
    }

    &:not(:disabled):hover {
        filter: brightness(0.9);
    }

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}