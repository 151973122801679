@import url('./constants.scss');

body, .main {
    background: var(--bg-dark-primary);
    height: 100%;
    color: var(--white);
    overflow-x: hidden;
}

h1, h2, label {
    font-family: 'Roboto', sans-serif;
}

.left-side {
    display: flex;
    justify-content: center;

    h1 {
        color: var(--white);
        span {
            border-bottom:solid var(--bg-cmp-primary) 10px;
            width:25px;
        }
    }
}

.form-user {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--bg-dark-secondary);
    padding: 20px;
    border-radius: 15px;
}

.user-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    background-color: var(--bg-dark-secondary);
    border-radius: 15px;
    padding: 20px;

    img {
        width: 100px;
        height: 100px;
        border-radius: 40%;
        margin-right: 16px;
    }
}

.form-floating {
    margin-top: 15px;
    padding: 0 0.75rem;

    &:focus {
        box-shadow: none;
        border-color: var(--border-cmp-primary); 
    }

    input {
        background: var(--bg-dark-secondary);
        color: var(--white);

        padding-bottom: 0 !important;
        padding-left: 0.2rem !important;

        border: none;
        border-radius: 0;
        border-bottom: 1px solid var(--grey);

        &:focus {
            box-shadow: none;
            border-color: var(--border-cmp-primary);
            background: var(--bg-dark-secondary);
            color: var(--white);
        }

        &:-webkit-autofill,
        &:-webkit-autofill:focus {
            transition: background-color 0s 600000s, color 0s 600000s;
        }
    }

    input[type=file] {
        padding: 2.5rem 1rem !important;

        &::file-selector-button {
            background: var(--bg-dark-secondary) ;
            color: var(--bg-cmp-primary);

            border: none;
            border-radius: 15px;

            cursor: pointer;

            transition: background .3s ease-in-out !important;
        }

        &::file-selector-button:hover {
            background: var(--grey-secondary) !important;
        }
    }

    label {
        &::after {
            background: var(--bg-dark-secondary) !important;
            margin-left: 1rem;
        }

        color: var(--grey-secondary) !important;
    }
}

.login-info {
    input {
        &:disabled {
            background: var(--bg-dark-secondary);
            
            border-radius: 15px;
            border: 1px solid var(--grey);

            padding-left: 0.7rem !important;
            color: var(--white);
        }
    }

    label {
        padding-left: 1.5rem !important;

        &::after {
            background: var(--bg-dark-secondary) !important;
            margin-left: 1rem;
        }

        color: var(--grey-secondary) !important;
    }

}


.save-cancel-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;

    margin-top: 20px;
}