@import url('../../styles/constants.scss');

.navbar {
    z-index: 999;
}

.navbar-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 20rem;
    padding: 0;
    margin: 0;
}

.checkbox {
    opacity: 0;
    position: absolute;
}

.labelChangeTheme {
    background-color: var(--bg-dark-primary);
    border-radius: 50px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 5px;
    position: relative;
    height: 26px;
    width: 50px;

    transform: scale(1.5);
}

.labelChangeTheme .ball {
    background-color: var(--white);
    border-radius: 50%;

    position: absolute;
    top: 2px;
    left: 2px;
    
    height: 22px;
    width: 22px;

    transform: translate(0px);
    transition: transform 0.2s linear;
}

.checkbox:checked + .labelChangeTheme .ball {
    transform: translate(24px);
}

.bi-moon {
    color: #f1c40f;
}

.bi-brightness-high-fill {
    color: #f39c12;
}