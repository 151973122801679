@import url('../../styles/constants.scss');

.actions-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
        width: 100px;
    }
}

#card {
    background-color: var(--bg-dark-secondary);
    border-radius: 15px;
    border-color: var(--bg-dark-secondary);

    min-height: 14rem;
    max-width: 18rem;
    width: 18rem;
}

.card-body {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--bg-dark-secondary);
    border-radius: 15px !important;
}

#card-title {
    color: var(--white);
}

#card-subtitle {
    color: var(--grey) !important;
}

#card-text {
    color: var(--white);
}