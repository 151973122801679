.profile-photo {
    cursor: pointer;
    img {
        width: 50px;
        height: 50px;

        border-radius: 40%;
    }
}

.box-dropdown {
    position: absolute;
    display: none;
    border: 1px solid var(--bg-cmp-primary);
    border-radius: 15px;

    background: var(--bg-dark-secondary);

    // margin-top: 5px;

    z-index: 1;

    ul {
        list-style: none;
        padding: 10px 15px;
        
        li {
            display: block;
            color: var(--white);
            margin: 10px 0;

            img, svg {
                width: 20px;
                height: 20px;
                border-radius: 0;
                margin-right: 10px;
            }

        }
        
        .profile:hover {
            color: var(--bg-cmp-secondary);

            svg {
                g {
                    fill: var(--bg-cmp-secondary);
                }
            }
        }
        
        .logout:hover {
            color: var(--bg-google-red);

            svg {
                fill: var(--bg-google-red);
                
                g {
                    stroke: var(--bg-google-red);
                }
            }
        }

        hr {
            color: var(--white);
            border-color: var(--white);
            margin: 10px 0;
        }
    }
}

.dropdown-profile:hover .box-dropdown {
    display: block;
}