.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Fundo escurecido */
  backdrop-filter: blur(10px); /* Efeito de desfoque no fundo */
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s; /* Animação de entrada */
  // overflow: auto; /* Permite rolagem quando necessário */
}

.modal-content {
  background: #2a2a2a; /* Cor de fundo do modal */
  padding: 20px;
  border-radius: 10px;
  // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  width: 90%;
  max-width: 1000px; /* Limite máximo de largura */
  max-height: 90%; /* Limite máximo de altura para evitar overflow da tela */
  // overflow-y: auto; /* Permite rolagem vertical se o conteúdo for muito longo */
  animation: slideIn 0.3s; /* Animação de deslizar */
}

.close-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;

  &:hover {
    color: #ff9800; /* Cor ao passar o mouse */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
