.outerDiv, .row {
    min-height: 100vh;
}

main {
    display: flex;
    padding-top: 3rem;
    justify-content: center;
    align-items: flex-start;

    background: #121212;
}

.row {
    justify-content: center;
    width: 100%;
    height: 100%;
}

.financial-data {
    input {
        transition: all 0.8s;
        &:disabled {
            background: var(--bg-dark-secondary);
            
            border-radius: 15px;
            border: 1px solid var(--grey);

            padding-left: 0.7rem !important;
            color: var(--white);
        }
    }

    input:disabled + label {
        padding-left: 1.5rem !important;

        &::after {
            background: var(--bg-dark-secondary) !important;
            margin-left: 1rem;
        }

        color: var(--grey-secondary) !important;
        transition: all 0.5s;
    }
}

#client-data {
    display: flex;
    flex-direction: column;
    gap: 16px;

    height: 100%;

    background-color: #212529;

    padding: 2rem;
    margin: 0 20px;
    border-radius: 15px;

    h1 {
        color: #fff;
    }
}

.form-floating {
    input {
        font-size: 14pt;
    }

    label {
        font-family: 'Poppins', sans-serif;
        font-size: 12pt;
        color: #6E7174;
    }
}

.input-group .input-group-text {
    background: var(--bg-dark-secondary);
    border: none;
    color: var(--grey-secondary);
    align-items: end;
    padding: 0;
    padding-bottom: 5px;
}

.client-cards {
    h2 {
        color: #fff;
    }
}

.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;

    justify-content: center;
}