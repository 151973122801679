:root {
    --black: #000;
    --white: #fff;
    --white-secondary: #f8f8f8;
    --grey: #808080;
    --grey-secondary: #a8a8b3;

    --bg-google-red: #ea4335;

    --bg-dark-primary: #121212;
    --bg-dark-secondary: #212529;
    --bg-dark-tertiary: #292d31;


    --bg-cmp-primary: #835afd;
    --bg-cmp-secondary: #9984da;
    --bg-cmp-danger: #DC3545;
    --bg-cmp-info: #0DCAF0;

    --border-cmp-primary: #BA68C8;
}
